import React, { Component, useMemo } from "react";
import { Modal } from "semantic-ui-react";
import { AxiosResponse, AxiosError } from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import _ from "lodash";
import { getRequest, deleteRequest } from "../../../../utils/httpRequest";
import { notification } from "../../../../utils/helpers";
import AddPaymentMethod from "./AddPaymentMethod";
import { Card } from "./Types";

interface Props {
  showPaymentMethodsModal: boolean;
  handleShowPaymentMethodsModal: (e: any, val: boolean) => void;
  handleShowSettingsModal: (val: boolean) => void;
}

interface State {
  cards: Card[];
  deleting: boolean;
}

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class PaymentMethods extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      deleting: false,
    };
  }

  componentDidMount = () => {
    this.getCards();
  };

  getCards = () => {
    return getRequest("/api/payment_methods.json")
      .then((response: AxiosResponse<Card[]>) => {
        this.setState({
          cards: response.data,
          deleting: false,
        });
      })
      .catch((err) => {
        this.setState({ cards: [] });
      });
  };

  updateCards = (card) => {
    let { cards } = this.state;

    cards.push(card);
    this.setState({ cards });
  };

  getCardImage = (brand) => {
    let img;
    try {
      img = require(
        `../../../../../../assets/images/payment_methods/${brand}.svg`,
      );
    } catch {
      img = require(`../../../../../../assets/images/payment_methods/card.svg`);
    }

    return img;
  };

  deleteCard = (e, cardId) => {
    e.preventDefault();

    if (confirm("Are you sure you want to remove this card?")) {
    } else {
      return false;
    }

    this.setState({ deleting: true });

    return deleteRequest(`/api/payment_methods/${cardId}.json`)
      .then((response: AxiosResponse) => {
        this.getCards();
      })
      .catch((error: AxiosError<{ error: string }>) => {
        this.setState({ deleting: false });
        notification(error?.response?.data?.error, "error");
      });
  };

  render() {
    const { showPaymentMethodsModal, handleShowPaymentMethodsModal } =
      this.props;

    const { cards, deleting } = this.state;

    return (
      <Modal
        size="small"
        open={showPaymentMethodsModal}
        className="profile-settings-modal payment-methods-modal"
      >
        <Modal.Header>
          <div className="close">
            <a
              className="ui left button"
              id="closeNav"
              onClick={(e) => handleShowPaymentMethodsModal(e, false)}
            >
              <i className="close icon" />
            </a>
          </div>
          <h2>Edit Payment Methods</h2>
        </Modal.Header>
        <Modal.Content>
          <div className="ui segment account-information">
            <h4>Payment Methods</h4>
            {cards?.length === 0 ? (
              <div> You don't have any cards</div>
            ) : (
              <div className="ui middle aligned divided list">
                {deleting && (
                  <div
                    className="ui active inverted dimmer"
                    style={{ zIndex: 1 }}
                  >
                    <div className="ui text loader">Deleting</div>
                  </div>
                )}
                {cards?.map((card, i) => (
                  <div className="item payment-method" key={i}>
                    <div className="right floated content delete">
                      {cards.length > 1 && (
                        <a
                          href="#"
                          onClick={(e) => this.deleteCard(e, card.id)}
                        >
                          <i className="trash alternate icon" />
                        </a>
                      )}
                    </div>
                    <img
                      className="ui avatar image brand"
                      src={this.getCardImage(card.brand)}
                      alt={card.brand}
                    />
                    <div className="content display">
                      <span className="card-number">
                        <i className="ellipsis horizontal icon" />
                        {card.last4}
                      </span>
                      <span className="expires-at">
                        Expires {card.exp_month}/{card.exp_year}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* @ts-ignore */}
            <Elements stripe={stripePromise}>
              <AddPaymentMethod updateCards={this.updateCards} />
            </Elements>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default PaymentMethods;
