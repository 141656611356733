import React from "react";
import { Link } from "react-router-dom";
import Option from "../../common/Option";

const Gift = ({ activeQuote, couponCode, refCode }) => (
  <div className="subscriptions">
    <div>
      <Link
        to={{
          pathname: "#",
          state: { couponCode, refCode },
        }}
      >
        <Option
          title="All-Access Yearly Subscription"
          meta="Unlimited access to all courses"
          discountAmount={
            activeQuote?.original_price?.amount > activeQuote?.amount
              ? activeQuote.original_price.formatted_amount
              : null
          }
          isActive={true}
          symbol={activeQuote ? activeQuote.currency : "$"}
          amount={activeQuote ? activeQuote.formatted_amount : "500"}
          code={activeQuote ? activeQuote.currency_code : "USD"}
        />
      </Link>
    </div>
  </div>
);

export default Gift;
