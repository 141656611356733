import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Header from "../common/Header";
import ProcessPayment from "./components/ProcessPayment";
import { Quotation } from "../../../stores/CoursePreviewStore/Types";
import {
  handleGiftPaymentSuccess,
  handleNonGiftPaymentSuccess,
} from "./service";
import { UserDetail, CompanyDetail } from "../BillingInfo/Types";
import { scrollIntoView } from "../../../utils/helpers";

const stripeStyles = {
  base: {
    fontSize: "16px",
    color: "#32325d",
    lineHeight: "40px",
    fontFamily: '"Averta CY", sans-serif',
    fontSmoothing: "antialiased",
    fontWeight: 400,
    "::placeholder": {
      color: "#CFD7E0",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

interface Props {
  kind: string;
  isExpanded: boolean;
  handleChange: (key: string, value: any) => void;
  token?: string;
  activeQuote: Quotation;
  userDetails: UserDetail;
  companyDetails: CompanyDetail;
  billingType: "user" | "company";
  isCurrentUserPresent: boolean;
}

interface State {
  isExpanded: boolean;
  userDetails: {
    name: string;
    email: string;
  };
}

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class CardDetails extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      userDetails: {
        name: "",
        email: "",
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isExpanded !== prevProps.isExpanded) {
      this.setState({
        isExpanded: this.props.isExpanded,
      });
    }

    if (this.props.userDetails !== prevProps.userDetails) {
      this.setState({ userDetails: this.props.userDetails });
    }
  }

  toggleView = (e) => {
    e.preventDefault();

    this.setState({ isExpanded: !this.state.isExpanded });
  };

  next = (e) => {
    e.preventDefault();
  };

  back = (e) => {
    e.preventDefault();

    const { activeQuote } = this.props;

    this.setState({ isExpanded: false });

    const element = document.getElementById("billing-info");
    scrollIntoView(element);

    const step = activeQuote.amount === 0 ? "plan" : "billing";

    (window as any)?.dataLayer.push({
      event: "customClick",
      elementId: "checkout-payment-back",
    });

    this.props.handleChange("step", step);
  };

  handlePaymentSuccess = (data) => {
    switch (this.props.kind) {
      case "PassGift":
      case "CourseGift":
        handleGiftPaymentSuccess(data, this.props.token);
        break;
      default:
        handleNonGiftPaymentSuccess(data);
        break;
    }
  };

  payUrl = () => {
    switch (this.props.kind) {
      case "PassGift":
      case "CourseGift":
        // api/gift_controller#update 🤷‍♀️
        return `/api/gift/${this.props.token}/pay`;
      default:
        // api/payment_controller#quote 🤷‍♀️
        return `/api/quote/${this.props.activeQuote?.id}/pay`;
    }
  };

  params = () => {
    const { billingType, userDetails, companyDetails } = this.props;

    switch (billingType) {
      case "user":
        return {
          billingEmail: userDetails.email,
          billingName: userDetails.name,
          address: userDetails.address,
          city: userDetails.city,
          postalCode: userDetails.postalCode,
          country: userDetails.country,
          vatNumber: "",
          vatType: "",
        };
        break;

      case "company":
        return {
          billingEmail: userDetails.email,
          billingName: companyDetails.billingName || userDetails.name,
          address: companyDetails.address,
          city: companyDetails.city,
          postalCode: companyDetails.postalCode,
          country: companyDetails.country,
          vatNumber: companyDetails.vatNumber,
          vatType: companyDetails.vatType,
        };
        break;
    }
  };

  updateUserDetails = (key, value) => {
    const { userDetails } = this.state;

    const updatedUserDetails = { ...userDetails, [key]: value };

    this.setState({ userDetails: updatedUserDetails });
    this.props.handleChange("userDetails", updatedUserDetails);
  };

  render() {
    const { activeQuote, isCurrentUserPresent } = this.props;
    const { isExpanded, userDetails } = this.state;

    return (
      <div className={isExpanded ? "expanded" : "closed"}>
        <hr />
        <Header
          title={
            activeQuote?.amount <= 0
              ? "Step 2: Your info"
              : "Step 3: Your card details"
          }
          toggleView={this.toggleView}
          isExpanded={isExpanded}
        />

        {isExpanded && (
          <>
            {/* @ts-ignore */}
            <Elements stripe={stripePromise}>
              <ProcessPayment
                isCurrentUserPresent={isCurrentUserPresent}
                userDetails={userDetails}
                payUrl={this.payUrl()}
                activeQuote={activeQuote}
                previousAction={this.back}
                params={this.params()}
                updateUserDetails={this.updateUserDetails}
                handlePaymentSuccess={this.handlePaymentSuccess}
              />
            </Elements>
          </>
        )}
      </div>
    );
  }
}

export default CardDetails;
