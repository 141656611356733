import React from "react";
import { Link } from "react-router-dom";
import Option from "../../common/Option";

const Lifetime = ({ activeQuote }) => (
  <div>
    <div className="subscriptions">
      <Link to="#">
        <Option
          discountAmount={
            activeQuote?.original_price?.amount > activeQuote?.amount
              ? activeQuote.original_price.formatted_amount
              : null
          }
          title="All-Access Lifetime Subscription"
          meta="Unlimited access to all courses"
          isActive={true}
          symbol={activeQuote?.currency}
          amount={activeQuote?.formatted_amount}
          code={activeQuote?.currency_code}
        />
      </Link>
    </div>
  </div>
);

export default Lifetime;
