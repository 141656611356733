import urlHelpers from "../../../utils/urlHelpers";

interface PriceableSummary {
  type: string;
  token: string;
  success_url: string;
}

interface PaymentUser {
  activation_token: string;
  is_activated: boolean;
  is_logged_in: boolean;
  email: string;
}

export const handleGiftPaymentSuccess = (data, token) => {
  if (data?.gift_datalayer?.tag_success) {
    (window as any)?.dataLayer.push(data.gift_datalayer.tag_success);
  }

  let destinationUrl = data.success_url || `/gift/${token}/success`;
  redirectToPaymentSuccess({ destinationUrl });
};

export const handleNonGiftPaymentSuccess = ({
  priceable_summary,
  payment_user,
  payment_datalayer,
}: {
  priceable_summary: PriceableSummary;
  payment_user: PaymentUser;
  payment_datalayer: any;
}) => {
  if (payment_datalayer?.tag_success) {
    (window as any)?.dataLayer.push(payment_datalayer.tag_success);
  }

  const destinationUrl =
    priceable_summary.success_url ||
    successUrlForNonGiftPurchase(priceable_summary);

  // if user is logged in, then cool, go to success page
  if (payment_user.is_logged_in) {
    redirectToPaymentSuccess({ destinationUrl });
  } else {
    // lets redirect them to a page where they can log in or activate their account
    const userActivationStatus = payment_user.is_activated
      ? "activated"
      : "deactivated";
    const paymentSuccessUrl = urlHelpers.payments.successfulPaymentPath({
      userActivationStatus,
      redirectUrl: destinationUrl || "",
      userEmail: payment_user.email || "",
      activationToken: payment_user.activation_token || "",
    });

    redirectToPaymentSuccess({ destinationUrl: paymentSuccessUrl });
  }
};

const successUrlForNonGiftPurchase = (
  priceableSummary: PriceableSummary,
): string => {
  switch (priceableSummary.type) {
    case "LifetimePass":
      return "/passes/lifetime/success";
    case "Pass":
      return `/passes/${priceableSummary.token}/success`;
    case "Course":
      return `/enrolments/${priceableSummary.token}`;
    default:
      return "/profile";
  }
};

const redirectToPaymentSuccess = ({
  destinationUrl,
}: {
  destinationUrl: string;
}): void => {
  window.location.href = destinationUrl;
};
