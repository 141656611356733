import React from "react";
import { Link } from "react-router-dom";
import Option from "../../common/Option";

const Upgrade = ({ activeQuote, priceable, couponCode, refCode, slug }) => (
  <div>
    <div className="subscriptions">
      <Link
        to={{
          pathname: "/pass/year/upgrade",
          state: { couponCode, refCode },
        }}
      >
        <Option
          title="All-Access Yearly Subscription"
          meta="Unlimited access to all courses"
          discountAmount={
            activeQuote?.original_price?.amount > activeQuote?.amount
              ? activeQuote.original_price.formatted_amount
              : null
          }
          isActive={["year"].includes(slug)}
          symbol={activeQuote ? activeQuote.currency : "$"}
          amount={activeQuote ? activeQuote.formatted_amount : "500"}
          code={activeQuote ? activeQuote.currency_code : "USD"}
        />
      </Link>
      <div className="select-payment-type">
        <Link
          to={{
            pathname: "/pass/year/upgrade",
            state: { couponCode, refCode },
          }}
          className={`ui button once ${
            slug === "year" || priceable?.interval === "year" ? "active" : ""
          }`}
        >
          Once-off payment
        </Link>
        <Link
          to={{
            pathname: "/pass/month/upgrade",
            state: { couponCode, refCode },
          }}
          className={`ui button instalment ${
            slug === "month" || priceable?.interval === "month" ? "active" : ""
          }`}
        >
          0% interest x 12 months
        </Link>
      </div>
    </div>
  </div>
);

export default Upgrade;
