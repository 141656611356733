import React, { Component } from "react";
import Checkout from "../Checkout";

interface Props {
  match: any;
  selectedCurrency: string;
  currentUser: {
    country: string;
    kind: string;
  };
  location: {
    state: {
      couponCode: string;
      refCode: string;
    };
  };
}

class CoursePay extends Component<Props, {}> {
  render() {
    const slug = this.props.match.params.slug;

    const params = new URLSearchParams(window.location.search);
    const couponCode = params.get("coupon_code");

    const refCode = this.props.location.state?.refCode;

    return (
      <>
        <Checkout
          slug={slug}
          paymentType="course"
          country={this.props.currentUser.country}
          paymentUrl={`/api/checkout/courses/${slug}`}
          refCode={refCode}
          couponCode={couponCode}
          selectedCurrency={this.props.selectedCurrency}
          isCurrentUserPresent={this.props.currentUser.kind === "current-user"}
        />
      </>
    );
  }
}

export default CoursePay;
