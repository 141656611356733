import React, { Component } from "react";
import Checkout from "../Checkout";

interface Props {
  match: {
    params: {
      slug: string;
    };
  };
  selectedCurrency: string;
  currentUser: {
    country: string;
    kind: string;
  };
}

class UpgradePay extends Component<Props, {}> {
  render() {
    const slug = this.props.match.params.slug;

    return (
      <div className="facelift-checkout">
        <Checkout
          slug={slug}
          path="upgrade"
          paymentUrl={`/api/checkout/upgrades/${slug}`}
          paymentType="subscription"
          selectedCurrency={this.props.selectedCurrency}
          isCurrentUserPresent={this.props.currentUser.kind === "current-user"}
        />
      </div>
    );
  }
}

export default UpgradePay;
