import React, { Component } from "react";
import { observer } from "mobx-react";
import GiftingImage from "../../../../assets/images/mastheads/responsive/gifting.jpg";
import GiftingImageMobile from "../../../../assets/images/mastheads/responsive/gifting-mobile.jpg";
import { getRequest } from "../../utils/httpRequest";
import { AxiosResponse } from "axios";
import { Gift } from "../../stores/GiftStore/Types";
import { Link } from "react-router-dom";

interface Props {
  match: any;
  currentUser: {
    kind: "current-user" | "current-user-absent";
  };
}

interface State {
  loaded: boolean;
  gift: Gift;
}

class GiftSuccess extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      gift: null,
      loaded: false,
    };
  }

  componentDidMount() {
    return getRequest(
      `/api/gift/${this.props.match.params.token}/success.json`,
    ).then((response: AxiosResponse) => {
      this.setState({ gift: response.data, loaded: true });
    });
  }

  render() {
    const { currentUser } = this.props;
    const { loaded, gift } = this.state;

    const renderButton = () => {
      let backUrl;
      let courseName;

      if (currentUser.kind == "current-user") {
        backUrl = "/profile";
        courseName = "your profile";
      } else if (gift?.category == "Course") {
        backUrl = `/courses/${gift?.priceable.slug}`;
        courseName = gift?.priceable.name;
      } else {
        backUrl = "/";
        courseName = "the homepage";
      }

      return (
        <Link to={backUrl} className="fl-button basic">
          Back to {courseName}
        </Link>
      );
    };

    return (
      <div className="ui pages" id="gift_success">
        {!loaded && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}

        <div className="masthead">
          <h1 className="ui header">Your gift is on it's way!</h1>
        </div>

        <div className="content">
          <div className="ui vertical segment padded">
            <div className="ui text container padded">
              <h1 className="ui header">Thanks for your support!</h1>
              <p>
                Bravo for giving the gift of knowledge, you will shortly receive
                a confirmation email with your receipt.
              </p>

              {renderButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(GiftSuccess);
