import React from "react";
import { Link } from "react-router-dom";
import Option from "../../common/Option";

const MonthlyFlexEd = ({ activeQuote }) => (
  <div className="subscriptions">
    <div>
      <Link to="#">
        <Option
          title="Pay as you go subscription"
          meta="Access to 1 course per month <br/> (One new course credit each month you renew)"
          isActive={true}
          symbol={activeQuote?.currency}
          amount={activeQuote?.formatted_amount}
          code={activeQuote?.currency_code}
        />
      </Link>
    </div>
  </div>
);

export default MonthlyFlexEd;
